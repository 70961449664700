import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  saving: false,
  error: null,
  items: [],
  selected: null,
  count: 0,
  page: 1,
  dates: {},
  data:{},
  summary: [],
  resultsPage: 1,
  resultCount: 0,
  results: [],
  download: []
};

export const surveysSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.items = [];
    },
    setSurveyPage: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.items = payload.data;
      state.count = payload.totalPages;
    },
    addData: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.items = [...state.items, ...payload];
    },
    setSaving: (state, { payload }) => {
      state.saving = payload;
    },
    delete: (state, { payload }) => {
      state.items = state.items.filter((item) => item.id !== payload);
      state.selected = null;
    },
    update: (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item === payload) {
          return payload;
        }
        return item;
      });
      state.selected = null;
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    setPage: (state, { payload }) => {
      if (payload <= state.count) {
        state.page = payload;
      }
    },
    setDateRange: (state, action) => {
      state.dates = {
        dateFrom: action.payload.since,
        dateTo: action.payload.until,
      };
    },
    setData: ( state, action) => {
      state.data = action.payload
    },
    setSummary: (state, action) => {
      state.summary = action.payload
    },
    setSurveResultPage: (state, {payload}) => {
      state.isLoading = false;
      state.error = null;
      state.results = payload.data;
      state.resultCount = payload.totalPages;
    },
    setResultsPage: (state, { payload }) => {
      if (payload <= state.resultCount) {
        state.resultsPage = payload;
      }
    },
    setResults: (state, action) => {
      state.results = action.payload.data
    },
    downloadSurveyResultPage: (state, {payload}) => {
      state.download = payload.data;
    },
  },
});

export const surveysActions = surveysSlice.actions;
export default surveysSlice.reducer;